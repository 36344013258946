import React from 'react'
import PropTypes from 'prop-types'

function HeaderShapes({ activePanel = 'default' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="1188"
      height="702"
      className={`hero-shapes-svg ${activePanel}`}
      viewBox="0 0 1188 702"
    >
      <defs>
        <path
          id="91aa5578"
          d="M1.5 0C42.093 0 75 33.13 75 74s-32.907 74-73.5 74c-.167 0-.334 0-.501-.002V.004z"
        />
        <path id="b59c08b0" d="M0 0h33c27.614 0 50 22.386 50 50v33H0V0z" />
        <path id="6439ea23" d="M94 0v94H0z" />
      </defs>
      <g
        fill="none"
        fillRule="evenodd"
        transform="translate(.521)"
        className="hero-header-shapes"
      >
        <path
          fill="#6BE4ED"
          d="M81.479 248v-36c0-19.882-16.118-36-36-36s-36 16.118-36 36 16.118 36 36 36h36z"
          opacity="0.2"
          id="circ-brdr0-top-left"
        />
        <path
          fill="#D8E4F6"
          d="M563.979 333c101.344 0 183.5 82.156 183.5 183.5S665.323 700 563.979 700l.5-.004V506l-183.705-.002C386.217 409.54 466.16 333 563.98 333z"
          opacity="0.88"
          id="circle-cutout-middle"
        />

        <path
          fill="#0A6CFF"
          d="M77.9789829,365 C118.571912,365 151.478983,398.130929 151.478983,439 C151.478983,479.869071 118.571912,513 77.9789829,513 C77.8117962,513 77.6447398,512.999438 77.4778157,512.998316 L77.4782278,365.004 Z"
          opacity=".1"
          id="half-circle-mid-left"
          transform="rotate(180)"
        />

        <rect
          width="44"
          height="44"
          x="88.479"
          fill="#5BE2EC"
          opacity="0.1"
          rx="22"
          id="circle-top-left"
        />
        <path
          fill="#5BE2EC"
          d="M1163.479 226c0 30.376-24.624 55-55 55-30.155 0-54.642-24.268-54.996-54.34l56.462.009-.015-55.65c29.705.769 53.549 25.09 53.549 54.981z"
          opacity="0.2"
          id="circle-cutout-top-right"
        />
        <circle
          cx="780.979"
          cy="471.5"
          r="138.5"
          fill="#5BE2EC"
          opacity="0.6"
          id="circle-middle"
        />
        <path
          fill="#5BE2EC"
          d="M361.979 22.577l7.093 4.096a2 2 0 011 1.732v8.19a2 2 0 01-1 1.732l-7.093 4.096a2 2 0 01-2 0l-7.093-4.096a2 2 0 01-1-1.732v-8.19a2 2 0 011-1.732l7.093-4.096a2 2 0 012 0z"
          opacity="0.1"
          id="hexagon-top-mid-left"
        />
        <path
          fill="#5BE2EC"
          d="M882.479 585.577l23.98 13.846a2 2 0 011 1.732v27.69a2 2 0 01-1 1.732l-23.98 13.846a2 2 0 01-2 0l-23.98-13.846a2 2 0 01-1-1.732v-27.69a2 2 0 011-1.732l23.98-13.846a2 2 0 012 0z"
          opacity="0.6"
          id="hexagon-bottom-right"
        />
        <path
          fill="#FFDB4D"
          d="M1061.479 517h50c0-27.614-22.386-50-50-50v50z"
          opacity="0.3"
          id="one-4th-circle-bottom-right"
        />
        <path
          fill="#5BE2EC"
          d="M1108.479 226h79c0 43.63-35.37 79-79 79v-79z"
          opacity="0.1"
          id="one-4th-circle-top-right"
        />
        <path
          fill="#E3EDFD"
          d="M1111.479 608v94h-94c0-51.915 42.085-94 94-94z"
          id="one-4th-circle-bottom-right-corner"
        />
        <path
          fill="#E6F1FF"
          d="M192.011 700c.788-29.682 24.966-53.5 54.675-53.5 29.71 0 53.888 23.818 54.676 53.5z"
          id="half-circle-bottom-left"
        />
        <path
          fill="#E3EDFD"
          d="M80,512 L80,512 L80,512 L80,592 L0,592 C-5.41083001e-15,547.81722 35.81722,512 80,512 Z"
          id="one-4th-circle-bottom-left"
        />

        <path
          fill="#E6F1FF"
          d="M80.479 64l112 112h-112z"
          opacity="0.3"
          id="triangle-top-left"
        />
        <g id="triangle-mid-left">
          <path
            fill="#FFDB4D"
            d="M78.479 255l109 109h-109z"
            opacity="0.3"
          />
          <path
            fill="#FFDB4D"
            d="M78.479 255l54.5 54.5-54.5 54.5z"
            opacity="0.3"
          />
        </g>
        <use
          fill="#5BE2EC"
          opacity="0.3"
          transform="translate(1014.479 325)"
          xlinkHref="#6439ea23"
          id="triangle-mid-right"
        />
        <path
          fill="#FFDB4D"
          d="M879.479 701c-54.124 0-98-43.876-98-98h98v98z"
          opacity="0.5"
          id="one-4th-circle-bottom-right-mid"
        />
        <path
          fill="#FFFAE6"
          d="M879.479 646h55v55h-55z"
          id="rect-bottom-right"
        />
        <path
          fill="#84DBED"
          d="M81.488 591.343l111.799 108.096-111.808.035z"
          opacity="0.3"
          id="triangle-bottom-left"
        />
      </g>
    </svg>
  )
}

HeaderShapes.propTypes = {
  activePanel: PropTypes.oneOf([
    'default',
    'personal',
    'academic',
    'enterprise'
  ])
}

export default HeaderShapes
