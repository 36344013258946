import React from 'react'
import PropTypes from 'prop-types'
import {
  CSSTransition,
  TransitionGroup
} from 'react-transition-group'

// Images
import defaultDesktop from '../images/hero-images/default@2x.png'
import defaultMobile from '../images/hero-images/default_mobile@2x.png'
import persDesktop from '../images/hero-images/pers@2x.png'
import persMobile from '../images/hero-images/pers_mobile@2x.png'
import acadDesktop from '../images/hero-images/acad@2x.png'
import acadMobile from '../images/hero-images/acad_mobile@2x.png'
import enteDesktop from '../images/hero-images/ente@2x.png'
import enteMobile from '../images/hero-images/ente_mobile.png'

export const UiShowcase = ({
  mobile,
  desktop,
  alt,
  activePanel = 'default',
  ...props
}) => (
  <div className="ui-showcase">
    <div className={`mobile ${activePanel}`}>
      <img src={mobile} alt="" />
    </div>
    <div className="desktop">
      <img
        src={desktop}
        alt={alt}
        {...props}
      />
    </div>
  </div>
)

UiShowcase.propTypes = {
  mobile: PropTypes.string.isRequired,
  desktop: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  activePanel: PropTypes.oneOf([
    'default',
    'personal',
    'academic',
    'enterprise'
  ])
}

const HeroImage = ({ type = 'default', ...props }) => (
  <TransitionGroup className="hero-images-group" enter exit>
    {
      {
        default: (
          <CSSTransition key="a6279c64" classNames="ui-showcase" timeout={350}>
            <UiShowcase
              mobile={defaultMobile}
              desktop={defaultDesktop}
              alt="Shows the UI of bizy, with the card: 'Where are we going on vacation this year?'"
              activePanel={type}
              {...props}
            />
          </CSSTransition>
        ),
        personal: (
          <CSSTransition key="eebae2a5" classNames="ui-showcase" timeout={350}>
            <UiShowcase
              mobile={persMobile}
              desktop={persDesktop}
              alt="Shows the UI of bizy, with the card: 'Where are we going on vacation this year?'"
              activePanel={type}
              {...props}
            />
          </CSSTransition>
        ),
        academic: (
          <CSSTransition key="d38acb0c" classNames="ui-showcase" timeout={350}>
            <UiShowcase
              mobile={acadMobile}
              desktop={acadDesktop}
              alt="Shows the UI of bizy, with the card: 'What will be the subject of our next profile assignment?'"
              activePanel={type}
              {...props}
            />
          </CSSTransition>
        ),
        enterprise: (
          <CSSTransition key="f128ae1b" classNames="ui-showcase" timeout={350}>
            <UiShowcase
              mobile={enteMobile}
              desktop={enteDesktop}
              alt="Shows the UI of bizy, with the card: 'Places we could go this year for the business trip'"
              activePanel={type}
              {...props}
            />
          </CSSTransition>
        )
      }[type]
    }
  </TransitionGroup>
)

HeroImage.propTypes = {
  type: PropTypes.oneOf([
    'default',
    'personal',
    'academic',
    'enterprise'
  ])
}

export default HeroImage
